<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <ButtonAdicionar :temPermissao="true" to="/empresas/adicionar" />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-row class="mt-4">
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :headers="cabecalho"
          :items="empresas"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="carregarEmpresas"
        >
          <template v-slot:[`item.status`]="{ item }">
            <span
              :class="{
                'status-ativo': item.status === 'Ativo',
                'status-inativo': item.status === 'Inativo',
              }"
            >
              {{ item.status }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="tables my-1">
              <v-img
                max-height="50"
                max-width="50"
                class="rounded-circle"
                :src="item.url_imagem"
              >
              </v-img>
              <table-btn-editar
                :temPermissao="
                  $store.getters['usuario/temPermissao']('editar.empresas')
                "
                :href="`/empresas/${item.id}/editar`"
              />
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import empresas from "@/api/empresas";

export default {
  components: {
    ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
      totalRegistros: 0,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Descrição", value: "pessoa.nome" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      empresas: [],
      paginacao: {},
      tab: null,
    };
  },

  methods: {
    async carregarEmpresas() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await empresas.listar({
          pagina: this.paginacao.page,
          por_pagina: this.paginacao.itemsPerPage,
        });
        this.empresas = resposta.data.data;
        this.empresas.forEach((item) => {
          if (item.ativo == 1) {
            item.status = "Ativo";
          } else {
            item.status = "Inativo";
          }
        });

        this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>
<style scoped>
.tables {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-ativo {
  color: rgb(58, 209, 68);
  font-weight: bold;
}
.status-inativo {
  color: rgb(245, 61, 61);
  font-weight: bold;
}
</style>
